.layout {
    min-height: 100vh;
}

.content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
    text-align: center;
}
.logo {
    float: left;
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}

.sider {
    background: #fff;
    margin: 50px 0 0 50px;
    padding: 10px;
    text-align: center;
}

.account{
    float: right;
    color: white;
}

.loading-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
